<template>
  <div id="form">
    <img class="img-fluid banner-img" src="../assets/images/banner-img.jpg">
    <div class="container">
      <div class="row" v-if="step >= 1 && step < 5">
        <div class="col-12 mt-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" v-bind:class="{ active: step == 1 }">History & Claims</li>
              <li class="breadcrumb-item"> > </li>
              <li class="breadcrumb-item" v-bind:class="{ active: step == 2 }">Personal Details</li>
              <li class="breadcrumb-item" > > </li>
              <li class="breadcrumb-item" v-bind:class="{ active: step == 3 }">Contact Information</li>
              <li class="breadcrumb-item" > > </li>
              <li class="breadcrumb-item" v-bind:class="{ active: step == 4 }">Confirmation & Submission</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center my-5">
          <h2 class="title" v-if="step === 1"> Let's Calculate Your Contribution</h2>
          <h2 class="title" v-if="step === 2"> Personal Details</h2>
          <h2 class="title" v-if="step === 3"> Contact Information</h2>
          <h2 class="title" v-if="step === 4"> Confirmation</h2>
          <h2 class="title" v-if="step === 5"> Successful </h2>
        </div>
      </div>
      <div class="row">
        <!-- Step 1 -->
        <div class="col-12 mb-5" v-show="step == 1">
          <div class="card question-card shadow-lg">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- Classification -->
                  <div class="form-group row">
                    <label for="classification" class="col-sm-3 col-form-label">Classification</label>
                    <div class="col-sm-9">
                      <div class="form-group">
                        <select
                            id="classification"
                            class="form-control"
                            v-model="formData.classification"
                            @change="onChangeClassification($event)"
                        >
                          <option v-bind:value="null" selected>Choose...</option>
                          <option v-for="list in classification_lists" v-bind:key="list.id" v-bind:value="list.name">{{ list.name }}</option>
                        </select>
                        <a :href="Brochure" target="_blank" style="float: right">Check The List</a>
                        <div class="invalid-feedback" v-if="formData.classification == null">Field is required.</div>
                      </div>
                    </div>
                  </div>

                  <!-- Limit of Liability -->
                  <div class="form-group row">
                    <label for="limit_of_liability" class="col-sm-3 col-form-label">Limit of Liability (RM)</label>
                    <div class="col-sm-9">
                      <select
                          id="limit_of_liability"
                          class="form-control"
                          v-model="formData.limit_of_liability"
                          @change="onChangeLimitLiability($event)"
                      >
                        <option v-bind:value="null" selected>Choose...</option>
                        <option v-for="(limit_list, index) in limit_of_liability_lists.prices" v-bind:key="index" v-bind:value="limit_list">{{ limit_list }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="formData.limit_of_liability == null">Field is required.</div>
                    </div>
                  </div>

                  <!-- Protection Start Date -->
                  <div class="form-group row">
                    <label for="protection_start_date" class="col-sm-3 col-form-label">Protection Start Date</label>
                    <div class="col-sm-9">
                      <datepicker id="protection_start_date" :format="customFormatter" :disabledDates="disabledDates" :open-date="new Date()" @selected="startDateSelected" v-model="formData.protection_start_date"></datepicker>
                      <span style="font-style: italic; font-size: 12px; font-weight: bold;">*Kindly provide an actual date preferably for us to provide a valid quote</span>
                      <div class="invalid-feedback" v-if="formData.protection_start_date == null">Field is required.</div>
                    </div>
                  </div>

                  <!-- Protection End Date -->
                  <div class="form-group row">
                    <label for="protection_end_date" class="col-sm-3 col-form-label">Protection End Date</label>
                    <div class="col-sm-9">
                      <datepicker id="protection_end_date" :format="customFormatter" :disabled="true" v-model="formData.protection_end_date"></datepicker>
                    </div>
                  </div>

                  <div class="form-group form-check">
                    <input type="checkbox" class="form-check-input" v-model="formData.agree_terms" id="agree_terms">
                    <label for="agree_terms" class="form-check-label">I understand and agree to the terms and conditions, and I meet all local regulations to practice my profession</label>
                    <div class="invalid-feedback" v-if="formData.agree_terms == false">You required to agree to the terms and conditions in order to continue.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-center my-5">
              <h2 class="title">Personal Information</h2>
            </div>
          </div>

          <div class="card question-card shadow-lg">
            <div class="card-body">
              <div class="row">
                <div class="col-12">

                  <!-- Full Name -->
                  <div class="form-group row">
                    <label for="full_name" class="col-sm-3 col-form-label">Name as Per IC</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" id="full_name" v-model="formData.full_name">
                      <div class="invalid-feedback" v-if="formData.full_name == ''">Field is required.</div>
                    </div>
                  </div>

                  <!-- Mobile Number -->
                  <div class="form-group row">
                    <label for="phone_no" class="col-sm-3 col-form-label">Mobile Number</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" id="phone_no" v-model="formData.phone_no">
                      <div class="invalid-feedback" v-if="formData.phone_no == ''">Field is required.</div>
                    </div>
                  </div>

                  <!-- Telephone Number (Office) -->
                  <div class="form-group row">
                    <label for="phone_no_office" class="col-sm-3 col-form-label">Telephone Number (Office)</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" id="phone_no_office" v-model="formData.phone_no_office">
                    </div>
                  </div>

                  <!-- Email -->
                  <div class="form-group row">
                    <label for="email" class="col-sm-3 col-form-label">Email</label>
                    <div class="col-sm-9">
                      <input type="email" class="form-control" id="email" v-model="formData.email">
                      <div class="invalid-feedback" v-if="formData.email == ''">Field is required.</div>
                    </div>
                  </div>

                  <!-- Contact Type -->
                  <fieldset class="form-group row">
                    <legend class="col-form-label col-sm-3 float-sm-left pt-0">Prefer Contact You Through?</legend>
                    <div class="col-sm-9">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="Phone" v-model="formData.contact_type">
                        <label class="form-check-label" for="gridRadios1">
                          Mobile Number
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="Email" v-model="formData.contact_type">
                        <label class="form-check-label" for="gridRadios2">
                          Email
                        </label>
                        <div class="invalid-feedback" v-if="formData.contact_type == null">Field is required.</div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>

          <div class="card question-card shadow-lg mt-5">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <ol>
                    <!-- PIP -->
                    <li>Do you currently have or have your ever had professional indemnity protection/ takaful/ insurance?<br> (If YES, please provide details) <br>
                      <div class="invalid-feedback" v-if="formData.have_pip == null">Field is required.</div>
                      <div class="btn-group btn-group-toggle mt-3" data-toggle="buttons">
                        <label class="btn btn-outline-primary btn-lg" v-on:click="is_have_pip(true)" :class="{ 'active' : parseInt(formData.have_pip) === 1 }">
                          <input type="radio" name="have_pip" v-model="formData.have_pip" autocomplete="off" value="1"> Yes
                        </label>
                        <label class="btn btn-outline-primary btn-lg" v-on:click="is_have_pip(false)" :class="{ 'active' : parseInt(formData.have_pip) === 0 }">
                          <input type="radio" name="have_pip" v-model="formData.have_pip" autocomplete="off" value="0" > No
                        </label>
                      </div>
                    </li>
                  </ol>
                  <ol type="a" v-show="is_show_have_pip">
                    <li>
                      Name of Indemnity Provider/ Takaful Operator/ Insurer
                      <input type="text" class="form-control mt-3" id="name_of_indemnity" v-model="formData.name_of_indemnity">
                    </li>
                    <li>
                      Expiry Date of Certificate/ Policy
                      <datepicker id="expiry_date" :format="customFormatter" v-model="formData.expiry_date"></datepicker>
                    </li>
                  </ol>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-12">
                  <ol start="2">
                    <li>
                      Have you ever been refused medical professional indemnity protection / takaful/ insurance?<br> (If YES, please provide details) <br>
                      <div class="invalid-feedback" v-if="formData.refuse_mpip == null">Field is required.</div>
                      <div class="btn-group btn-group-toggle mt-3" data-toggle="buttons">
                        <label class="btn btn-outline-primary btn-lg" v-on:click="is_refuse_mpip(true)" :class="{ 'active' : parseInt(formData.refuse_mpip) === 1 }">
                          <input type="radio" name="refuse_mpip" v-model="formData.refuse_mpip" autocomplete="off" value="1"> Yes
                        </label>
                        <label class="btn btn-outline-primary btn-lg" v-on:click="is_refuse_mpip(false)" :class="{ 'active' : parseInt(formData.refuse_mpip) === 0 }">
                          <input type="radio" name="refuse_mpip" v-model="formData.refuse_mpip" autocomplete="off" value="0"> No
                        </label>
                      </div>

                      <div class="form-group mt-3" v-show="is_show_refuse_mpip">
                        <label for="refuse_mpip_detail">Provide your details below:</label>
                        <textarea class="form-control" id="refuse_mpip_detail" v-model="formData.refuse_mpip_detail" rows="5"></textarea>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-center my-5">
              <h2 class="title"> Claims Information</h2>
            </div>
          </div>

          <div class="card question-card shadow-lg">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <ol>
                    <li>
                      Are you aware of any claim or threat against you now or have you ever been involved, directly or indirectly in a claim, or suit arising form your practice?<br>
                      <div class="invalid-feedback" v-if="formData.aware_any_claim == null">Field is required.</div>
                      <div class="btn-group btn-group-toggle mt-3" data-toggle="buttons">
                        <label class="btn btn-outline-primary btn-lg" :class="{ 'active' : parseInt(formData.aware_any_claim) === 1 }">
                          <input type="radio" name="aware_any_claim" v-model="formData.aware_any_claim" autocomplete="off" value="1"> Yes
                        </label>
                        <label class="btn btn-outline-primary btn-lg" :class="{ 'active' : parseInt(formData.aware_any_claim) === 0 }">
                          <input type="radio" name="aware_any_claim" v-model="formData.aware_any_claim" autocomplete="off" value="0"> No
                        </label>
                      </div>
                    </li>
                    <li>
                      Are you aware of any circumstance or incident that may give rise to a claim against you in the future?<br>
                      <div class="invalid-feedback" v-if="formData.aware_any_incident == null">Field is required.</div>
                      <div class="btn-group btn-group-toggle mt-3" data-toggle="buttons">
                        <label class="btn btn-outline-primary btn-lg" :class="{ 'active' : parseInt(formData.aware_any_incident) === 1 }">
                          <input type="radio" name="aware_any_incident" v-model="formData.aware_any_incident" autocomplete="off" value="1"> Yes
                        </label>
                        <label class="btn btn-outline-primary btn-lg" :class="{ 'active' : parseInt(formData.aware_any_incident) === 0 }">
                          <input type="radio" name="aware_any_incident" v-model="formData.aware_any_incident" autocomplete="off" value="0"> No
                        </label>
                      </div>
                    </li>
                    <li>
                      Have you ever been subjected to any disciplinary/ coronial inquiry, investigation or complaint by a regulatory body or council?<br>
                      <div class="invalid-feedback" v-if="formData.subject_any_disciplinary == null">Field is required.</div>
                      <div class="btn-group btn-group-toggle mt-3" data-toggle="buttons">
                        <label class="btn btn-outline-primary btn-lg" :class="{ 'active' : parseInt(formData.subject_any_disciplinary) === 1 }">
                          <input type="radio" name="subject_any_disciplinary" v-model="formData.subject_any_disciplinary" autocomplete="off" value="1"> Yes
                        </label>
                        <label class="btn btn-outline-primary btn-lg" :class="{ 'active' : parseInt(formData.subject_any_disciplinary) === 0 }">
                          <input type="radio" name="subject_any_disciplinary" v-model="formData.subject_any_disciplinary" autocomplete="off" value="0"> No
                        </label>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
              <hr>
              <div class="row" v-show="isAnswerYes === true">
                <div class="col-12">
                  <p>If you have answered "YES" to any of the above questions, you must complete the information below and attach relevant documents with regard to your claim, circumstance, inquiry or investigation. It is important that you understand that this certificate is written on a "Claims Made" basis. You must notify all claims or circumstance that may lead to claims to us as soon as you become aware of them.</p>
                </div>
                <div class="col-12">
                  <!-- Incident Date -->
                  <div class="form-group row">
                    <label for="incident_date" class="col-sm-3 col-form-label">Incident Date</label>
                    <div class="col-sm-9">
                      <datepicker id="incident_date" :format="customFormatter" v-model="formData.incident_date"></datepicker>
                    </div>
                  </div>

                  <!-- Notification Date -->
                  <div class="form-group row">
                    <label for="notification_date" class="col-sm-3 col-form-label">Notification Date</label>
                    <div class="col-sm-9">
                      <datepicker id="notification_date" :format="customFormatter" v-model="formData.notification_date"></datepicker>
                    </div>
                  </div>

                  <!-- Source of Incident -->
                  <div class="form-group row">
                    <label for="source_of_incident" class="col-sm-3 col-form-label">Source of Incident (Writ/ Letter of Demand/ Circumstance/ Inquiry)</label>
                    <div class="col-sm-9">
                      <textarea class="form-control" id="source_of_incident" v-model="formData.source_of_incident" rows="5"></textarea>
                    </div>
                  </div>

                  <!-- Category of Incident -->
                  <div class="form-group row">
                    <label for="category_of_incident" class="col-sm-3 col-form-label">Category of Incident (Diagnosis / Treatment)</label>
                    <div class="col-sm-9">
                      <textarea class="form-control" id="category_of_incident" v-model="formData.category_of_incident" rows="5"></textarea>
                    </div>
                  </div>

                  <!-- Settled? -->
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Settled?</label>
                    <div class="col-sm-9">
                      <div class="btn-group btn-group-toggle mt-3" data-toggle="buttons">
                        <label class="btn btn-outline-primary btn-lg" :class="{ 'active' : parseInt(formData.settled) === 1 }">
                          <input type="radio" name="settled" v-model="formData.settled" autocomplete="off" value="1"> Yes
                        </label>
                        <label class="btn btn-outline-primary btn-lg" :class="{ 'active' : parseInt(formData.settled) === 0 }">
                          <input type="radio" name="settled" v-model="formData.settled" autocomplete="off" value="0"> No
                        </label>
                      </div>
                    </div>
                  </div>

                  <!-- Full Registration Certificate -->
                  <div class="form-group row">
                    <label for="full_registration_certificate" class="col-sm-3 col-form-label">Full Registration Certificate</label>
                    <div class="col-sm-9">
                      <input type="file" class="form-control-file" @change="process_file_full_registration_certificate($event)" id="full_registration_certificate">
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" v-if="isAnswerYes === false && premium_price != ''">
                <div class="col-12 text-center">
                  Premium Price: <span class="price">{{ 'RM ' + premium_price + '.00' }}</span>
                </div>
              </div>

              <div class="row">
                <div class="col-12 text-center my-5">
                  <div class="alert alert-danger mt-1" role="alert" v-if="is_error">
                    There some required information in order to proceed.
                  </div>
                  <button class="btn btn-primary btn-lg" v-show="!loading" v-if="isAnswerYes === false" v-on:click="nextAction(1)">Next</button>
                  <button class="btn btn-primary btn-lg" v-show="!loading" v-if="isAnswerYes === true" v-on:click="submitAction()">Proceed Submission</button>
                  <button class="btn btn-primary btn-lg" v-show="loading" disabled>Please wait...</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Step 2 -->
        <div class="col-12" v-show="step == 2">
          <div class="card question-card shadow-lg">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- IC -->
                  <div class="form-group row">
                    <label for="ic_number" class="col-sm-3 col-form-label">MyKad/ Passport Number</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" id="ic_number" v-model="formData.ic_number">
                      <div class="invalid-feedback" v-if="formData.ic_number == ''">Field is required. Eg format: xxxxxxxxxxxx without dash</div>
                    </div>
                  </div>

                  <!-- Nationality -->
                  <div class="form-group row">
                    <label for="nationality" class="col-sm-3 col-form-label">Nationality</label>
                    <div class="col-sm-9">
                      <select id="nationality" class="form-control" v-model="formData.nationality">
                        <option selected>Choose...</option>
                        <option v-for="(country, index) in countries" v-bind:key="index" v-bind:value="country.name">{{ country.name }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="formData.nationality == null">Field is required.</div>
                    </div>
                  </div>

                  <!-- Date of Birth -->
                  <div class="form-group row">
                    <label for="dob" class="col-sm-3 col-form-label">Date Of Birth</label>
                    <div class="col-sm-9">
                      <datepicker id="dob" placeholder="MM/DD/YYYY" :typeable="true" :format="customFormatter" v-model="formData.dob"></datepicker>
                      <div class="invalid-feedback" v-if="formData.dob == null">Field is required.</div>
                    </div>
                  </div>

                  <!-- Gender -->
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Gender</label>
                    <div class="col-sm-9">
                      <div class="btn-group btn-group-toggle mt-3" data-toggle="buttons">
                        <label class="btn btn-outline-primary btn-lg">
                          <input type="radio" name="gender" v-model="formData.gender" autocomplete="off" value="Male"> Male
                        </label>
                        <label class="btn btn-outline-primary btn-lg">
                          <input type="radio" name="gender" v-model="formData.gender" autocomplete="off" value="Female"> Female
                        </label>
                      </div>
                      <div class="invalid-feedback" v-if="formData.gender == null">Field is required.</div>
                    </div>
                  </div>

                  <!-- MDA Member -->
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">(MRN, NSR & MeRITS) Member</label>
                    <div class="col-sm-9">
                      <div class="btn-group btn-group-toggle mt-3" data-toggle="buttons">
                        <label class="btn btn-outline-primary btn-lg">
                          <input type="radio" name="mda_member" v-model="formData.mda_member" autocomplete="off" value="1"> Yes
                        </label>
                        <label class="btn btn-outline-primary btn-lg">
                          <input type="radio" name="mda_member" v-model="formData.mda_member" autocomplete="off" value="0"> No
                        </label>
                      </div>
                    </div>
                  </div>

                  <!-- MDA Number -->
                  <div class="form-group row">
                    <label for="mda_number" class="col-sm-3 col-form-label">(MRN, NSR & MeRITS) Number</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" id="mda_number" v-model="formData.mda_number">
                    </div>
                  </div>

                  <!-- MDA Registration Date -->
                  <div class="form-group row">
                    <label for="mda_registration_date" class="col-sm-3 col-form-label">(MRN, NSR & MeRITS) Registration Date</label>
                    <div class="col-sm-9">
                      <datepicker id="mda_registration_date" :format="customFormatter" v-model="formData.mda_registration_date"></datepicker>
                    </div>
                  </div>

                  <!-- Annual Practicing Certificate -->
                  <div class="form-group row">
                    <label for="annual_practicing_cert" class="col-sm-3 col-form-label">Annual Practicing Certificate</label>
                    <div class="col-sm-9">
                      <input type="file" class="form-control-file" id="annual_practicing_cert" @change="process_file_annual_practicing_cert($event)" aria-describedby="annual_practicing_cert_help">
                      <small id="annual_practicing_cert_help" class="text-muted">
                        Only upload pdf, png, jpg or doc format
                      </small>
                    </div>
                  </div>

                  <!-- Full Practicing Certificate -->
                  <div class="form-group row">
                    <label for="full_registration_cert" class="col-sm-3 col-form-label">Full Practicing Certificate</label>
                    <div class="col-sm-9">
                      <input type="file" class="form-control-file" id="full_registration_cert" @change="process_file_full_registration_cert($event)" aria-describedby="full_registration_cert_help">
                      <small id="full_registration_cert_help" class="text-muted">
                        Only upload pdf, png, jpg or doc format
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 text-center">
                  <div class="alert alert-danger mt-1" role="alert" v-if="is_error">
                    There some required information in order to proceed.
                  </div>
                  <button type="button" class="btn btn-link back-btn btn-lg" v-on:click="prevAction">Back</button>
                  <button class="btn btn-primary btn-lg" v-on:click="nextAction(2)">Proceed</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Step 3 -->
        <div class="col-12" v-show="step === 3">
          <div class="card question-card shadow-lg">
            <div class="card-body">
              <div class="row">
                <div class="col-12">

                  <!-- Mailing Address -->
                  <div class="form-group row">
                    <label for="address_line" class="col-sm-3 col-form-label">Mailing Address</label>
                    <div class="col-sm-9">
                      <textarea class="form-control" id="address_line" rows="3" v-model="formData.address_line"></textarea>
                      <div class="invalid-feedback" v-if="formData.address_line == ''">Field is required.</div>
                    </div>
                  </div>

                  <!-- Postcode -->
                  <div class="form-group row">
                    <label for="postcode" class="col-sm-3 col-form-label">Postcode</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" id="postcode" v-model="formData.postcode">
                      <div class="invalid-feedback" v-if="formData.postcode == ''">Field is required.</div>
                    </div>
                  </div>

                  <!-- City -->
                  <div class="form-group row">
                    <label for="city" class="col-sm-3 col-form-label">City</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" id="city" v-model="formData.city">
                      <div class="invalid-feedback" v-if="formData.city == ''">Field is required.</div>
                    </div>
                  </div>

                  <!-- City -->
                  <div class="form-group row">
                    <label for="state" class="col-sm-3 col-form-label">State</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" id="state" v-model="formData.state">
                      <div class="invalid-feedback" v-if="formData.state == ''">Field is required.</div>
                    </div>
                  </div>

                  <!-- Country -->
                  <div class="form-group row">
                    <label for="country" class="col-sm-3 col-form-label">Country</label>
                    <div class="col-sm-9">
                      <select id="country" class="form-control" v-model="formData.country">
                        <option selected>Choose...</option>
                        <option v-for="(country, index) in countries" v-bind:key="index" v-bind:value="country.name">{{ country.name }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="formData.country == null">Field is required.</div>
                    </div>
                  </div>

                  <!-- Place of Practice -->
                  <div class="form-group row">
                    <label for="place_of_practice" class="col-sm-3 col-form-label">Place of Practice</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" id="place_of_practice" v-model="formData.place_of_practice">
                      <div class="invalid-feedback" v-if="formData.place_of_practice == ''">Field is required.</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 text-center">
                  <div class="alert alert-danger mt-1" role="alert" v-if="is_error">
                    There some required information in order to proceed.
                  </div>
                  <button type="button" class="btn btn-link back-btn btn-lg" v-on:click="prevAction">Back</button>
                  <button class="btn btn-primary btn-lg" v-on:click="nextAction(3)">Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Step 4 -->
        <div class="col-12" v-show="step === 4">
          <div class="card question-card shadow-lg">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <!-- Classification -->
                  <div class="form-group row">
                    <label for="classification" class="col-sm-3 col-form-label">Classification</label>
                    <div class="col-sm-9">
                      <div class="form-group">
                        <select
                            id="classification"
                            class="form-control"
                            v-model="formData.classification"
                            @change="onChangeClassification($event)"
                        >
                          <option v-bind:value="null" selected>Choose...</option>
                          <option v-for="list in classification_lists" v-bind:key="list.id" v-bind:value="list.name">{{ list.name }}</option>
                        </select>
                        <div class="invalid-feedback" v-if="formData.classification == null">Field is required.</div>
                      </div>
                    </div>
                  </div>


                  <!-- Limit of Liability -->
                  <div class="form-group row">
                    <label for="limit_of_liability" class="col-sm-3 col-form-label">Limit of Liability (RM)</label>
                    <div class="col-sm-9">
                      <select
                          id="limit_of_liability"
                          class="form-control"
                          v-model="formData.limit_of_liability"
                          @change="onChangeLimitLiability($event)"
                      >
                        <option v-bind:value="null" selected>Choose...</option>
                        <option v-for="(limit_list, index) in limit_of_liability_lists.prices" v-bind:key="index" v-bind:value="limit_list">{{ limit_list }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="formData.limit_of_liability == null">Field is required.</div>
                    </div>
                  </div>

                  <!-- Protection Start Date -->
                  <div class="form-group row">
                    <label for="protection_start_date" class="col-sm-3 col-form-label">Protection Start Date</label>
                    <div class="col-sm-9">
                      <datepicker id="protection_start_date" :format="customFormatter" :disabledDates="disabledDates" :open-date="new Date()" v-model="formData.protection_start_date"></datepicker>
                      <span style="font-style: italic; font-size: 12px; font-weight: bold;">*Kindly provide an actual date preferably for us to provide a valid quote</span>
                      <div class="invalid-feedback" v-if="formData.protection_start_date == null">Field is required.</div>
                      {{ formData.formatted_protection_start_date }}
                    </div>
                  </div>

                  <!-- Protection End Date -->
                  <div class="form-group row">
                    <label for="protection_end_date" class="col-sm-3 col-form-label">Protection Start Date</label>
                    <div class="col-sm-9">
                      <datepicker id="protection_end_date" :format="customFormatter" :disabled="true" v-model="formData.protection_start_date"></datepicker>
                    </div>
                  </div>

                  <!-- Premium Price Preview -->
                  <div class="form-group row" v-if="premium_price != ''">
                    <label for="premium_price" class="col-sm-3 col-form-label">Premium Price</label>
                    <div class="col-sm-9">
                      <div class="price">{{ 'RM ' + premium_price + '.00' }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-center my-5">
              <h2 class="title">Declaration</h2>
            </div>
          </div>

          <div class="card question-card shadow-lg">
            <div class="card-body">
              <div class="row">

                <!-- confirm declaration -->
                <div class="col-12 mt-4">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="confirm_declaration" v-model="formData.confirm_declaration">
                    <label class="form-check-label" for="confirm_declaration">I confirm that I have read, understand and agree to the <a href="#" data-toggle="modal" data-target="#declaration_modal">Declarations & Aqad</a> for Section A, B, C, and I confirm that declaration is true and correct for Section E</label>
                  </div>
                  <div class="invalid-feedback" v-if="formData.confirm_declaration == false">Field is required.</div>
                </div>

                <!-- unable declaration -->
                <div class="col-12 mt-4">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="unable_declaration" v-on:change="unable_declaration_check" v-model="formData.unable_declaration">
                    <label class="form-check-label" for="unable_declaration">I am unable to make the above declaration for my professional practice due to the reason(s) below</label>
                  </div>
                  <textarea class="form-control" id="unable_declaration_detail" rows="3" v-show="is_show_unable_declaration" v-model="formData.unable_declaration_detail"></textarea>
                </div>

                <!-- agree_withdraw -->
                <div class="col-12 mt-4">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="agree_withdraw" v-model="formData.agree_withdraw">
                    <label class="form-check-label" for="agree_withdraw">I agree that if I withdraw my application prior to inception of the certificate, I will be charged an administration fee of 50% of the contribution paid.</label>
                  </div>
                  <div class="invalid-feedback" v-if="formData.agree_withdraw == false">Field is required.</div>
                </div>

                <!-- wish apply clinic liability cover -->
                <div class="col-12 my-4">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="liability_cover" v-model="formData.liability_cover">
                    <label class="form-check-label" for="liability_cover">Please indicate if you wish to apply for clinic liability cover for separate legal entity/ Sdn Bhd.</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-center my-5">
              <div class="alert alert-danger mt-1" role="alert" v-if="is_error">
                There some required information in order to proceed.
              </div>
              <button type="button" class="btn btn-link back-btn btn-lg" v-on:click="prevAction" v-show="!loading">Back</button>
              <button class="btn btn-primary btn-lg" v-on:click="nextAction(4)" v-show="!loading">Confirm</button>
              <button class="btn btn-primary btn-lg" v-show="loading" disabled>Please wait...</button>
            </div>
          </div>
        </div>

        <!-- Success -->
        <div class="col-12 mb-5" v-if="step == 5">
          <div class="card question-card shadow-lg">
            <div class="card-body">
              <div class="row">
                <div class="col-12 text-center" v-if="this.formData.contact_type === 'Phone'">
                  <p>Your application has been successfully submitted. Our personnel will get in touch with you soon for the confirmation and payment.</p>
                </div>

                <div class="col-12 text-center" v-if="this.formData.contact_type === 'Email'">
                  <p>Your application has been successfully submitted. We will email you the copy of the application to the email that you provided. Our personnel will get in touch with you soon for the confirmation and payment.</p>
                  <p>We will email you a copy of the application to the email you’ve provided.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="declaration_modal" tabindex="-1" aria-labelledby="declaration_modal_label" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Declarations</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><span style="font-size:13px;">I/We agree to participate in this General Takaful scheme based on the principle of Takaful. I/We agree to pay the contribution on the basis of Tabarru&rsquo; (donation) for the purpose of mutual support of other participants and upon payment of the contribution to the General Takaful Fund (Fund), I/We am/are entitled to the Takaful cover as per the terms and conditions contained in the Takaful Certificate. Payment of sum covered to participants is payable from the Fund based on the concept of Tabarru&rsquo;.</span></p>
                <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><span style="font-size:13px;">This scheme also applies the Wakalah (agency) concept, whereby I/We appoint EGTB to act on My /Our behalf to invest and manage the Fund. The Fund is collectively owned by the Participants where Tabarru&apos; portion of the contribution is placed for the purpose of takaful. &nbsp;Accordingly, I/We agree to pay the upfront Wakalah Fee (as shown in the Product Disclosure Sheet and the Takaful Certificate) to EGTB, as a deduction of certain amount from contribution, to cover the expenses of investing and managing the Fund.</span></p>
                <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><span style="font-size:13px;">I/We agree to authorize EGTB to delegate any rights, duties and obligations to any third party as EGTB deems fit for the purpose of achieving the objective to invest and manage the Fund, provided that EGTB will remain liable and responsible for all such rights, duties and obligations towards Me/Us.&nbsp;</span></p>
                <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><span style="font-size:13px;">I/We understand that at the end of each financial year, the distributable surplus (if any) from the Fund will be determined annually and will only be payable for annual Certificate. The distribution, if any, makes allowance for contingency provisions, and is subject to the surplus policy approved by Our Shariah Committee. I/We agree that 50% of the distributable surplus (if any) will be paid to EGTB for operating and managing the Fund based on the contract of Ju&rsquo;alah. The balance of 50% will be shared amongst participants whose Takaful certificates have not terminated and who have not made any claim prior to the expiry of their takaful certificates.</span></p>
                <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><span style="font-size:13px;">In the event of insufficient balance in the Fund to pay Our Takaful Benefits during the period of Takaful, EGTB will make good the balance in the Fund under the principle of Qard (interest-free loan) from the Shareholder&rsquo;s Fund provided that the insufficiency is not due to EGTB&rsquo;s mismanagement or negligence. If the insufficiency is due to EGTB&rsquo;s mismanagement or negligence, EGTB will make the outright transfer for the insufficiency. I/We further agree that our future surplus arising from the Fund during our Takaful period can be used to pay for outstanding Qard to EGTB.</span></p>
                <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><span style="font-size:13px;">I/We further agree that if the surplus or any sum payable is less than Ringgit Malaysia Ten (RM10.00), it will automatically be credited to charitable fund which will be utilized as Amal Jariah on My/Our behalf. The fund will be distributed to eligible recipients as approved by EGTB&rsquo;s Shariah Committee for charitable purposes.</span></p>
                <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><strong><span style="font-size:13px;">Definitions:</span></strong></p>
                <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><strong><span style="font-size:13px;">&ldquo;General Takaful Fund (&ldquo;Fund&rdquo;)&rdquo;</span></strong><span style="font-size:13px;">&nbsp;means the Participants&rsquo; account where Tabarru&rsquo; portion of the Contribution is placed for the purpose of Takaful. The Fund is collectively owned by the Participants and the Sum Covered shown in the Takaful Schedule is payable from the Fund.</span></p>
                <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><strong><span style="font-size:13px;">&ldquo;Ju&rsquo;alah&rdquo;</span></strong><span style="font-size:13px;">&nbsp;is a wage contract. It is an exchange contract for a known or unknown task, that is difficult to precisely determine and for which payment is due only once the work has been completed. &nbsp; In relation to this Certificate, it refers to the reward given to the Takaful Operator (EGTB) agreed upfront by the Participant and the Takaful Operator for good management of the fund.</span></p>
                <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><strong><span style="font-size:13px;">&ldquo;Qard&rdquo;</span></strong><span style="font-size:13px;">&nbsp;</span><span style="font-size:13px;">in the context of this Certificate, means an interest-free loan which is given by the Takaful Operator (EGTB) to the&nbsp;</span><span style="font-size:13px;">General Takaful Fund</span><span style="font-size:13px;">&nbsp;when it becomes insufficient to fulfil its Takaful obligation provided that the insufficiency is not due to EGTB&rsquo;s mismanagement or negligence. The loan will be repaid by the future surpluses from the&nbsp;</span><span style="font-size:13px;">General Takaful Fund. If the insufficiency is due to EGTB&rsquo;s mismanagement or negligence, EGTB will make the outright transfer for the insufficiency.</span></p>
                <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><strong><span style="font-size:13px;">&ldquo;Tabarru&rdquo;</span></strong><span style="font-size:13px;">&nbsp;means contribution donation or gift. In the context of this Certificate, this means Contribution for the purpose of Takaful. This portion is placed in the General Takaful Fund.</span></p>
                <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><strong><span style="font-size:13px;">&ldquo;Takaful&rdquo;</span></strong><span style="font-size:13px;">&nbsp;means mutual assistance, based on the spirit of brotherhood and solidarity, whereby the Participants agree to assist each other financially in case of certain defined needs.&nbsp;</span></p>
                <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><strong><span style="font-size:13px;">&ldquo;Wakalah&rdquo;&nbsp;</span></strong><span style="font-size:13px;">refers to a contract where a party, as principal authorizes another party as his agent to perform a particular task on matters that may be delegated with or without imposition of a fee. In the context of this Certificate, this means that the Participant have appointed EGTB to invest and manage the General Takaful Fund on his/her behalf. The Participant have also authorized EGTB to delegate its rights, duties and obligations to any third party as it deems fit. In the event of such delegation, EGTB will remain liable and responsible for all such rights, duties and obligations towards the Participant.</span></p>
                <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><strong><span style="font-size:13px;">&ldquo;We&rdquo;, &ldquo;Us&rdquo;, &ldquo;our&rdquo;,</span></strong><span style="font-size:13px;">&nbsp;means Etiqa General Takaful Berhad (&ldquo;EGTB&rdquo;)</span></p>
                <p style='margin:0cm;font-size:11px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;'><strong><span style="font-size:13px;">&ldquo;You&rdquo;, &ldquo;your&rdquo;</span></strong><span style="font-size:13px;">&nbsp;means the participant named in the Schedule.</span></p>
                <p style='margin:0cm;font-size:11px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;'><span style="font-size:13px;">&nbsp;</span></p>
                <p style='margin:0cm;font-size:11px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;'><strong><span style="font-size:13px;">PERSONAL DATA PROTECTION ACT 2010&nbsp;</span></strong></p>
                <p style='margin:0cm;font-size:11px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><span style="font-size:13px;">I/ We agree to allow Etiqa General Takaful to process My / Our personal data, including sensitive personal data, with the intention of entering into a contract of takaful in compliance with the provisions of the Personal Data Protection Act 2010.&nbsp;</span></p>
                <p style='margin:0cm;font-size:11px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><span style="font-size:13px;">I/ We agree that any personal data collected or held by Etiqa General Takaful, whether contained in this application or subsequently obtained, may be held, used, processed and disclosed by Etiqa General Takaful to individuals or organizations related to and associated with Etiqa General Takaful, or any selected third parties (within or outside Malaysia, including medical institutions, retakaful, claim adjusters, claim investigators, solicitors, industry associations, regulators, statutory bodies, and government authorities), for the purpose of processing this application, providing subsequent service related to it, and to communicate with me for such purposes.&nbsp;</span></p>
                <p style='margin:0cm;font-size:11px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;text-align:justify;'><span style="font-size:13px;">I/ We understand that I/ We have a right to obtain access to, and to request correction of any personal data held by Etiqa General Takaful concerning me. I/ We understand that such request can be made by completing the Access Request Form available at all Etiqa General Takaful branches or contacting Etiqa General Takaful via email at PDPA@etiqa.com.my. I/ We understand that in accordance with the provisions of the PDPA, I/ We may contact the Customer Service Centre at Etiqa Oneline 1300 13 8888 for the details of My/ Our personal data and that such information shall only be granted upon verification of my identification.&nbsp;</span></p>
                <p style='margin:0cm;font-size:11px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;'><span style="font-size:16px;">&nbsp;</span></p>
                <p style='margin:0cm;font-size:11px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;'><span style="font-size:16px;">&nbsp;</span></p>
                <p style='margin:0cm;font-size:11px;font-family:"Calibri",sans-serif;margin-right:-3.2pt;'><span style="font-size:13px;">By accepting this quotation, I hereby confirm that I have read, understand and agree to the declarations.&nbsp;</span></p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BgImage from '../assets/images/image_11.png';
import BgImage2 from '../assets/images/image_12.png';
import Brochure from '../assets/images/MedPro.pdf';
import { required, requiredIf } from 'vuelidate/lib/validators'
import axios from 'axios'

export default {
  name: "Form",
  data () {
    return {
      BgImage,
      BgImage2,
      Brochure,
      step: 1,
      is_show_have_pip: false,
      is_show_refuse_mpip: false,
      is_show_unable_declaration: false,
      loading: false,

      is_error: false,

      premium_price: '',

      formData: {
        //step 1
        classification: null,
        limit_of_liability: null,
        protection_start_date: null,
        protection_end_date: null,
        agree_terms: false,
        contact_type: null,
        is_enquiry: 0,


        //step 2
        have_pip: null,
        name_of_indemnity: null,
        expiry_date: null,
        refuse_mpip: null,
        refuse_mpip_detail: null,
        aware_any_claim: null,
        aware_any_incident: null,
        subject_any_disciplinary: null,
        incident_date: null,
        notification_date: null,
        source_of_incident: '',
        category_of_incident: '',
        full_registration_certificate: null,
        settled: null,

        //step 3

        ic_number: '',
        nationality: null,
        dob: null,
        gender: null,
        mda_member: null,
        mda_number: '',
        mda_registration_date: null,
        annual_practicing_cert : null,
        full_registration_cert : null,

        //step 4
        full_name: '',
        phone_no: '',
        phone_no_office: '',
        email: '',
        address_line: '',
        postcode: '',
        city: '',
        state: '',
        country: null,
        place_of_practice: '',

        //step 5 Delaration
        confirm_declaration: false,
        unable_declaration: false,
        unable_declaration_detail: '',
        agree_withdraw: false,
        liability_cover: false
      },

      classification_lists: [],
      limit_of_liability_lists: [],
      countries: [],
      disabledDates: {
        to: new Date(Date.now())
      }
    }
  },
  validations: {
    formData: {
      //step 1
      classification: required,
      limit_of_liability: required,
      protection_start_date: required,
      protection_end_date: required,
      agree_terms: required,
      contact_type: required,


      //step 2
      have_pip: required,
      name_of_indemnity: required,
      expiry_date: required,
      refuse_mpip: required,
      refuse_mpip_detail: required,
      aware_any_claim: required,
      aware_any_incident: required,
      subject_any_disciplinary: required,
      //incident_date: required,
     // notification_date: required,
      //source_of_incident: required,
      //category_of_incident: required,
      //full_registration_certificate: required,

      //step 3
      full_name: required,
      ic_number: required,
      nationality: required,
      dob: required,
      gender: required,
      mda_member: required,
      mda_number: requiredIf((form) => {
        return form.mda_member === true
      }),
      mda_registration_date:  requiredIf((form) => {
        return form.mda_member === true
      }),
      annual_practicing_cert :  requiredIf((form) => {
        return form.mda_member === true
      }),
      full_registration_cert :  requiredIf((form) => {
        return form.mda_member === true
      }),

      //step 4
      phone_no: required,
      phone_no_office: required,
      email: required,
      address_line: required,
      postcode: required,
      city: required,
      state: required,
      country: required,
      place_of_practice: required,

      //step 5 Delaration
      confirm_declaration: required,
      unable_declaration: required,
      unable_declaration_detail: required,
      agree_withdraw: required,
      liability_cover: required
    }
  },
  computed: {

    isAnswerYes () {
      if (this.formData.refuse_mpip == 1 ||
          this.formData.aware_any_claim == 1 ||
          this.formData.aware_any_incident == 1 ||
          this.formData.subject_any_disciplinary == 1) {

         return true
      }
      else {
        return false
      }
    }
  },
  mounted() {
    this.classification_lists = [
    {
      id: 1,
      name: "Specialist (Low Risk)",
      prices: [
        "1,000,000",
        "1,500,000",
        "2,000,000",
        "3,000,000",
        "5,000,000",
        "10,000,000",
        "15,000,000",
        "20,000,000"
      ],
      premiums: [
        "1,597",
        "1,897",
        "2,196",
        "2,496",
        "3,195",
        "4,100",
        "4,800",
        "5,500"
      ]
    },
    {
      id: 2,
      name: "Specialist (Medium Risk)",
      prices: [
        "1,000,000",
        "1,500,000",
        "2,000,000",
        "3,000,000",
        "5,000,000",
        "10,000,000",
        "15,000,000",
        "20,000,000"
      ],
      premiums: [
        "3,195",
        "3,799",
        "4,299",
        "4,999",
        "6,299",
        "8,050",
        "9,300",
        "10,300"
      ]
    },
    {
      id: 3,
      name: "Specialist (High Risk)",
      prices: [
        "1,000,000",
        "1,500,000",
        "2,000,000",
        "3,000,000",
        "5,000,000",
        "10,000,000",
        "15,000,000",
        "20,000,000"
      ],
      premiums: [
        "12,500",
        "14,850",
        "16,720",
        "19,950",
        "26,400",
        "33,650",
        "38,789",
        "42,870"
      ]
    },
    {
      id: 4,
      name: "Obstetrics & Gynaecology",
      prices: [
        "1,000,000",
        "1,500,000",
        "2,000,000",
        "3,000,000",
        "5,000,000",
        "10,000,000",
        "15,000,000",
        "20,000,000"
      ],
      premiums: [
        "16,496",
        "19,496",
        "21,955",
        "25,994",
        "34,995",
        "44,600",
        "51,500",
        "56,850"
      ]
    },
    {
      id: 5,
      name: "Bariatric Surgery",
      prices: [
        "1,000,000",
        "1,500,000",
        "2,000,000",
        "3,000,000",
        "5,000,000",
        "10,000,000",
        "15,000,000",
        "20,000,000"
      ],
      premiums: [
        "10,998",
        "12,997",
        "14,697",
        "17,946",
        "23,995",
        "30,600",
        "35,300",
        "39,000"
      ]
    },
    {
      id: 6,
      name: "Cosmetic & Aesthetic Surgery",
      prices: [
        "1,000,000",
        "1,500,000",
        "2,000,000",
        "3,000,000",
        "5,000,000",
        "10,000,000",
        "15,000,000",
        "20,000,000"
      ],
      premiums: [
        "19,896",
        "23,595",
        "26,994",
        "31,296",
        "41,994",
        "53,550",
        "61,700",
        "68,199"
      ]
    },
    {
      id: 7,
      name: "Plastic & Reconstructive Surgery",
      prices: [
        "1,000,000",
        "1,500,000",
        "2,000,000",
        "3,000,000",
        "5,000,000",
        "10,000,000",
        "15,000,000",
        "20,000,000"
      ],
      premiums: [
        "7,998",
        "9,498",
        "10,698",
        "12,997",
        "15,996",
        "20,400",
        "23,500",
        "26,000"
      ]
    },
    {
      id: 8,
      name: "Gynaecology",
      prices: [
        "1,000,000",
        "1,500,000",
        "2,000,000",
        "3,000,000",
        "5,000,000",
        "10,000,000",
        "15,000,000",
        "20,000,000"
      ],
      premiums: [
        "8,998",
        "10,698",
        "11,997",
        "14,397",
        "20,995",
        "26,800",
        "30,850",
        "34,100"
      ]
    },
    {
      id: 9,
      name: "Orthopaedic & Trauma Surgery",
      prices: [
        "1,000,000",
        "1,500,000",
        "2,000,000",
        "3,000,000",
        "5,000,000",
        "10,000,000",
        "15,000,000",
        "20,000,000"
      ],
      premiums: [
        "7,998",
        "9,498",
        "10,698",
        "12,597",
        "15,996",
        "20,400",
        "23,500",
        "26,000"
      ]
    },
    {
      id: 10,
      name: "Nuerosurgery",
      prices: [
        "1,000,000",
        "1,500,000",
        "2,000,000",
        "3,000,000",
        "5,000,000",
        "10,000,000",
        "15,000,000",
        "20,000,000"
      ],
      premiums: [
        "9,998",
        "11,897",
        "13,302",
        "15,996",
        "19,996",
        "25,500",
        "29,400",
        "32,500"
      ]
    },
    {
      id: 11,
      name: "Spinal Surgery",
      prices: [
        "1,000,000",
        "1,500,000",
        "2,000,000",
        "3,000,000",
        "5,000,000",
        "10,000,000",
        "15,000,000",
        "20,000,000"
      ],
      premiums: [
        "17,996",
        "21,295",
        "23,945",
        "28,796",
        "37,795",
        "48,160",
        "55,500",
        "61,360"
      ]
    },
    {
      id: 12,
      name: "Private General Practitioner",
      prices: [
        "500,000",
        "1,000,000",
        "1,500,000",
        "2,000,000",
        "3,000,000",
        "5,000,000"
      ],
      premiums: ["719", "948", "1,098", "1,298", "1,398", "1,697"]
    }
  ];

   //this.countries = ['Malaysia', 'Canada', 'Indonesia']

    /*
    window.axios.get('/api/classification-lists')
        .then((res) => {
          this.classification_lists = res.data.data;
        })
        .finally(() => {

        })

    window.axios.get('/api/limit-of-liability-lists')
        .then((res) => {
          this.limit_of_liability_lists = res.data.data;
        })
        .finally(() => {

        })
     */

    window.axios.get(this.$hostname+'/api/countries/get')
        .then((res) => {
          this.countries = res.data.countries;
        })
        .finally(() => {

        })

    /*
    window.axios.get('http://localhost:8888/.netlify/functions/verify-tokensignature')
        .then((res) => {
          console.log(res.data)
        })
        .finally(() => {

        })
        */
  },
  methods: {
    startDateSelected () {

      this.$nextTick(() => {
        console.log(this.formData.protection_start_date)
        this.formData.protection_end_date = this.moment(this.formData.protection_start_date).add(1, 'year').subtract(1, 'days').format('YYYY/MM/DD');
      })
    },
    onChangeClassification () {
      for (let i=0; i < this.classification_lists.length; i++)
      {
        if (this.formData.classification == this.classification_lists[i].name)
        {
          this.limit_of_liability_lists = this.classification_lists[i];

        }
      }
    },
    onChangeLimitLiability () {
      for (let i=0; i < this.classification_lists.length; i++)
      {
        if (this.formData.classification == this.classification_lists[i].name)
        {
          for (let j=0; j < this.classification_lists[i].prices.length; j++)
          {
            if (this.formData.limit_of_liability == this.classification_lists[i].prices[j]) {
              console.log(this.classification_lists[i].premiums[j]);
              this.premium_price = this.classification_lists[i].premiums[j];
            }
          }
        }
      }
    },
    customFormatter (date) {
      return this.moment(date).format('DD/MM/YYYY');
    },
    formatForm (form) {
      let formData = new FormData();

      // maps js object into a form data object
      Object.keys(form).forEach((key) => {
        formData.append(key, form[key]);
      });

      return formData
    },
    async nextAction (step) {

      this.is_error = false;

      if (step === 1)
      {
        if (this.formData.classification != null &&
            this.formData.limit_of_liability != null &&
            this.formData.protection_start_date != null &&
            this.formData.protection_end_date != null &&
            this.formData.agree_terms != false &&
            this.formData.have_pip != null &&
            this.formData.refuse_mpip != null &&
            this.formData.aware_any_claim != null &&
            this.formData.aware_any_incident != null &&
            this.formData.subject_any_disciplinary != null &&
            this.formData.full_name != '' &&
            this.formData.phone_no != '' &&
            this.formData.email != '' &&
            this.formData.contact_type != null
        ) {
          this.is_error = false;

          this.step++;
        }
        else {
          this.is_error = true;
        }
      }
      else if (step === 2) {

        if (this.formData.ic_number != '' &&
            this.formData.nationality != null &&
            this.formData.dob != null &&
            this.formData.gender != null
            /*this.formData.mda_member != null &&
            this.formData.mda_number != '' &&
            this.formData.mda_registration_date != null */
        ) {
          this.is_error = false;
          this.step++;
        }
        else {
          this.is_error = true;
        }
      }
      else if (step === 3) {

        if (this.formData.address_line != '' &&
            this.formData.postcode != '' &&
            this.formData.city != '' &&
            this.formData.state != '' &&
            this.formData.country != null &&
            this.formData.place_of_practice != ''
        ) {
          this.is_error = false;
          this.step++;
        }
        else {
          this.is_error = true;
        }
      }
      else if (step === 4)
      {
        if (this.formData.confirm_declaration != false &&
            this.formData.agree_withdraw != false &&
            this.formData.classification != null &&
            this.formData.limit_of_liability != null &&
            this.formData.protection_start_date != null &&
            this.formData.protection_end_date != null
        ){

          this.loading = true;

          this.formData.protection_start_date = this.moment(this.formData.protection_start_date).format('Y-MM-DD')
          this.formData.protection_end_date = this.moment(this.formData.protection_end_date).format('Y-MM-DD')

          if (this.formData.incident_date) {
            this.formData.incident_date = this.moment(this.formData.incident_date).format('Y-MM-DD')
          }

          if (this.formData.notification_date) {
            this.formData.notification_date = this.moment(this.formData.notification_date).format('Y-MM-DD')
          }

          if (this.formData.mda_registration_date)
          {
            this.formData.mda_registration_date = this.moment(this.formData.mda_registration_date).format('Y-MM-DD')
          }

          this.formData.dob = this.moment(this.formData.dob).format('Y-MM-DD')
          this.formData.is_enquiry = 0

/*          if (this.formData.full_registration_certificate) {
            console.log(this.formData.full_registration_certificate);
            let urls = await this.getSignedUrls(this.formData.full_registration_certificate)
            await this.uploadFile(urls.putUrl, this.formData.full_registration_certificate)
            this.formData.full_registration_certificate = urls.resourceUrl
          }

          if (this.formData.annual_practicing_cert) {
            let urls = await this.getSignedUrls(this.formData.annual_practicing_cert)
            await this.uploadFile(urls.putUrl, this.formData.annual_practicing_cert)
            this.formData.annual_practicing_cert = urls.resourceUrl
          }

          if (this.formData.full_registration_cert) {
            let urls = await this.getSignedUrls(this.formData.full_registration_cert)
            await this.uploadFile(urls.putUrl, this.formData.full_registration_cert)
            this.formData.full_registration_cert = urls.resourceUrl
          }*/
          let formatted_form = this.formatForm(this.formData)
          //complete data form submission
          /*await axios.post('http://localhost:3000/api/quote/create', formatted_form )*/
          await axios.post(this.$hostname+'/api/quote/create', formatted_form )
              .then((res) => {
                if (res.data.success == true)
                {
                    /*axios.post('http://localhost:3000/api/order/create', {*/
                    axios.post(this.$hostname+'/api/order/create', {
                    name: this.formData.full_name,
                    email: this.formData.email,
                    quote_id: res.data.record_id,
                    address_line: this.formData.address_line,
                    address_line_2: '',
                    postcode: this.formData.postcode,
                    state: this.formData.state,
                    country: this.formData.nationality,
                    contact_no: this.formData.phone_no,
                    contact_email: this.formData.email,
                    amount: parseInt(this.premium_price.replace(',', ''))
                  }).then((res) => {
                    if (res.data.success == true) {
                      this.loading = false
                      this.step = 5
                    }
                  }).catch( err => {
                    console.log(err)
                  })
                }
              }).catch( err => {
                console.log(err)
              })
        }
        else {
          this.is_error = true;
        }
      }
    },
   async submitAction () {

     if (this.formData.classification != null &&
         this.formData.limit_of_liability != null &&
         this.formData.protection_start_date != null &&
         this.formData.protection_end_date != null &&
         this.formData.agree_terms != false &&
         this.formData.have_pip != null &&
         this.formData.refuse_mpip != null &&
         this.formData.aware_any_claim != null &&
         this.formData.aware_any_incident != null &&
         this.formData.subject_any_disciplinary != null &&
         this.formData.full_name != '' &&
         this.formData.phone_no != '' &&
         this.formData.email != '' &&
         this.formData.contact_type != null
     ) {
       this.is_error = false;

       this.loading = true;

       this.formData.protection_start_date = this.moment(this.formData.protection_start_date).format('Y-MM-DD')
       this.formData.protection_end_date = this.moment(this.formData.protection_end_date).format('Y-MM-DD')

       if (this.formData.incident_date) {
         this.formData.incident_date = this.moment(this.formData.incident_date).format('Y-MM-DD')
       }

       if (this.formData.notification_date) {
         this.formData.notification_date = this.moment(this.formData.notification_date).format('Y-MM-DD')
       }

       if (this.formData.expiry_date) {
          this.formData.expiry_date = this.moment(this.formData.expiry_date).format('Y-MM-DD')
       }

       this.formData.is_enquiry = 1
       /*this is post to s3, since moving it to laravel, this will be removed*/
/*       if (this.formData.full_registration_certificate) {
         console.log(this.formData.full_registration_certificate);
         let urls = await this.getSignedUrls(this.formData.full_registration_certificate)
         await this.uploadFile(urls.putUrl, this.formData.full_registration_certificate)
         this.formData.full_registration_certificate = urls.resourceUrl
       }*/

       let formatted_form = this.formatForm(this.formData)

       //incomplete data form submission
           /*await axios.post('http://localhost:3000/api/quote/create', formatted_form )*/
           await axios.post(this.$hostname+'/api/quote/create', formatted_form)
           .then((res) => {
             if (res.data.success == true)
             {
               /*axios.post('http://localhost:3000/api/order/create', {*/
               axios.post(this.$hostname+'/api/order/create', {
                 name: this.formData.full_name,
                 email: this.formData.email,
                 quote_id: res.data.record_id,
                 contact_no: this.formData.phone_no,
                 contact_email: this.formData.email,
                 amount: 0
               }).then((res) => {
                 if (res.data.success === true) {
                   this.loading = false;
                   this.step = 5;
                 }
               }).catch( err => {
                 console.log(err)
               })
             }
           })
           .catch( err => {
             console.log(err)
           })
     }
     else {
       this.is_error = true;
     }
    },
    prevAction () {
      this.step--;
    },
    is_have_pip (status) {

      this.is_show_have_pip = status;
    },
    is_refuse_mpip (status) {

      this.is_show_refuse_mpip = status;
    },
    unable_declaration_check (e) {

      if (e.target.checked == true) {
        this.is_show_unable_declaration = true;
      }
      else {
        this.is_show_unable_declaration = false;
      }
    },
    async process_file_full_registration_certificate (e) {
      console.log(e.target.files)
      if (e.target.files.length > 0) {
        this.formData.full_registration_certificate = e.target.files[0]
      }
    },
    process_file_annual_practicing_cert (e) {
      if (e.target.files.length > 0) {
        this.formData.annual_practicing_cert = e.target.files[0]
      }
    },
    process_file_full_registration_cert(e) {
      if (e.target.files.length > 0) {
        this.formData.full_registration_cert = e.target.files[0]
      }
    },
    uploadFile(url, file) {
      console.log('Upload file to S3', url)
      return new Promise((resolve, reject) => {
        axios.put(url, file, {
          headers: {
            'Content-Type': file.type,
          }
        })
        .then(() => {
          resolve()
        })
        .catch(() => {
          reject()
        })
      })
    },
    getSignedUrls(file) {
      // get PUT link first
      return new Promise((resolve, reject) => {
        axios.post('/api/get-s3-signed-url', {
          clientFilename: file.name,
          mimeType: file.type
        })
            .then(res => {
              console.log(res)
              resolve(res.data)
            })
            .catch(err => {
              console.log(err)
              reject(false)
            })
      })
    }
  }
}
</script>
